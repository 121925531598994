module.exports = {
  quantityField: {
    id: '57f40c9f-ad96-4b89-8d38-fcff858f7b4f',
    title: 'Failure Quantity',
    type: 'input',
    subtype: 'number',
    direction: 'horizontal',
    caption: null,
    pattern: null,
    minDate: null,
    maxDate: null,
    fieldIds: [],
    tableId: null,
    tableType: null,
    lookupFieldType: null,
    lookupField: null,
    runFileParser: false,
    fileParser: null,
    constant: true,
    minRequiredRows: 0,
    helpText: 'Enter quantity',
    helpImage: '',
    required: false,
    placeholder: '',
    options: [],
    defaultValue: 1,
    translations: {
      he: {
        options: [],
        title: 'כמות פסולים',
        caption: '',
        helpText: 'הכנס כמות'
      }
    }
  },
  removeStockItemsCheckBox: {
    id: '5df0a206-084b-455f-8957-4b61b3da6aec',
    title: 'Remove Stock Items',
    type: 'checkbox',
    subtype: '',
    helpText: null,
    placeholder: '',
    required: false,
    maxSelections: 1,
    direction: 'horizontal',
    options: [],
    constant: true,
    deletedAt: null,
    lookupFieldType: null,
    translations: {
      he: {
        options: [],
        title: 'הסר בנים',
        caption: '',
        helpText: ''
      }
    }
  },
  changeSerialNumberCheckBox: {
    id: '9453c592-9ce2-4bf0-af88-112a0632c5f7',
    title: 'Change Serial Number',
    type: 'checkbox',
    subtype: '',
    helpText: null,
    placeholder: '',
    required: false,
    maxSelections: 1,
    direction: 'horizontal',
    options: [],
    constant: true,
    deletedAt: null,
    lookupFieldType: null,
    translations: {
      he: {
        options: [],
        title: 'החלף מספר סידורי',
        caption: '',
        helpText: ''
      }
    }
  },
  newSerialNumberField: {
    id: 'f63ced09-1610-4b85-ac0a-a9813cbefb52',
    title: 'New Serial Number',
    type: 'input',
    subtype: 'text',
    direction: 'horizontal',
    caption: null,
    pattern: null,
    minDate: null,
    maxDate: null,
    fieldIds: [],
    tableId: null,
    tableType: null,
    lookupFieldType: null,
    lookupField: null,
    runFileParser: false,
    fileParser: null,
    constant: true,
    minRequiredRows: 0,
    helpText: 'Enter Serial Number',
    helpImage: '',
    required: false,
    placeholder: '',
    options: [],
    translations: {
      he: {
        options: [],
        title: 'מספר סריאלי חדש',
        caption: '',
        helpText: 'הכנס מספר סריאלי'
      }
    }
  }
}
